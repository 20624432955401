import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import React from "react";
import tw from "twin.macro";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";
import { SEO } from "../components";
import { typography } from "../styles";
import { graphql } from "gatsby";

const OurTeam = ({ data: { allStrapiBio } }) => {
  const team = allStrapiBio.edges.map((a) => a.node);
  return (
    <>
      <SEO title="Our Team" />
      <BlankPageNoImage header="OUR TEAM" ariaLabel="OUR TEAM">
        <div tw="max-w-screen-md mx-auto mt-16">
          {team
            .sort((a, b) => a.ordering - b.ordering)
            .map(({ name, title, qualifications, biography, image }) => (
              <div
                key={name}
                tw="flex flex-col col-span-2 my-4 mb-16 md:mb-32 lg:col-span-1 sm:px-4 md:px-8"
              >
                <GatsbyImage
                  image={getImage(image.localFile || image)}
                  alt={name}
                  width={250}
                  height={250}
                  quality={100}
                  loading="lazy"
                  tw="self-center block mx-auto mb-10 rounded-full w-[250px] h-[250px] sm:mx-0"
                />
                <h4
                  css={[
                    typography.header,
                    tw`self-center text-3xl leading-tight`,
                  ]}
                >
                  {name}
                </h4>
                <h5 tw="self-center mt-4 mb-8 font-medium text-[22px]">
                  {title}
                </h5>
                <h6 tw="mt-8 font-light">Qualifications:</h6>
                <p tw="mb-10 font-light whitespace-pre-wrap text-sonic">
                  {qualifications}
                </p>
                {biography?.data?.biography ? (
                  <div
                    tw="font-light leading-relaxed"
                    dangerouslySetInnerHTML={{
                      __html: biography.data.biography,
                    }}
                  />
                ) : null}
              </div>
            ))}
        </div>
      </BlankPageNoImage>
      <LocationBlock />
    </>
  );
};

export default OurTeam;

export const query = graphql`
  query OurTeamPageQuery {
    allStrapiBio {
      edges {
        node {
          name
          title
          ordering
          qualifications
          biography {
            data {
              biography
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250)
              }
            }
          }
        }
      }
    }
  }
`;
